import { Display } from '../../../common/style/Display'
import { theme } from '../../../styles/theme'

export function ShopLabsHero() {
  return (
    <>
      <Display isNotebookUp>
        <svg width="350" height="253" viewBox="0 0 350 253" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11709_22187)">
            <rect width="318" height="253" rx="8" fill="#CEE9EE" />
            <path
              d="M555.433 114.188C574.706 199.197 558.085 192.671 549.003 251.526L7.69642 262.536C3.6466 95.4824 53.5731 36.4754 158.02 54.3152C219.139 64.7544 278.972 41.7474 357.287 22.3007C430.06 4.23012 535.804 27.6085 555.433 114.188Z"
              fill="#BBDCE1"
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <path
                d="M50.9999 98.016C50.9999 110.345 107.108 120.339 176.32 120.339C245.533 120.339 237.071 108.476 237.071 96.1477C237.071 83.8191 245.533 75.6931 176.32 75.6931C107.108 75.6931 50.9999 85.6874 50.9999 98.016Z"
                fill="#D9D9D9"
              />
            </g>
            <path
              d="M251.592 105.542L223.466 116.561L159.409 16.3032L179.267 13.2911C180.529 13.0997 181.818 13.2061 183.031 13.6017L240.492 32.3405C243.344 33.2706 245.458 35.6867 246.002 38.6368L256.654 96.4408C257.364 100.295 255.241 104.112 251.592 105.542Z"
              fill="#A5CCD2"
            />
            <path
              d="M139.891 76.2031L152.982 21.2395C153.981 17.0447 158.39 14.6354 162.46 16.0599L220.921 36.5214C223.708 37.4968 225.629 40.0603 225.782 43.009L229.278 110.322C229.559 115.722 224.057 119.529 219.101 117.363L144.052 84.5525C140.828 83.1428 139.075 79.6266 139.891 76.2031Z"
              fill="#8CB9C0"
            />
            <path
              d="M152.901 79.367L159.967 25.7539C160.117 24.6103 161.281 23.8942 162.37 24.2753L216.394 43.1835C217.166 43.4538 217.662 44.2066 217.607 45.0228L213.599 104.067C213.515 105.315 212.223 106.107 211.072 105.616L153.991 81.2771C153.24 80.9571 152.795 80.1761 152.901 79.367Z"
              fill="#708B8F"
            />
            <path
              d="M217.607 44.4579L213.658 103.066C213.618 103.654 213.043 104.047 212.485 103.858C209.424 102.817 199.898 99.4342 183.272 92.2973C168.454 85.9368 160.357 81.892 156.976 80.1213C155.785 79.4977 155.082 78.2457 155.145 76.9032C155.344 72.6779 155.919 62.7688 157.328 51.2024C158.894 38.3534 161.434 28.3082 162.236 25.3221C162.37 24.8217 162.897 24.5501 163.388 24.716L216.374 42.613C217.156 42.8771 217.662 43.6346 217.607 44.4579Z"
              fill="#5F6262"
            />
            <g filter="url(#filter0_d_11709_22187)">
              <path
                d="M209.597 73.2917L199.982 69.8169C199.845 69.7672 199.74 69.6545 199.7 69.514L197.923 63.281C197.802 62.8581 197.211 62.8358 197.059 63.2483L189.245 84.4327C189.072 84.9022 188.378 84.7881 188.364 84.2878L187.311 45.7439C187.297 45.2358 186.587 45.13 186.425 45.6121L178.172 70.2817C178.015 70.7506 177.329 70.6674 177.288 70.1747L176.163 56.4502C176.128 56.0231 175.576 55.8773 175.334 56.2315L173.181 59.3925C173.057 59.5757 172.818 59.6422 172.616 59.5499L164.537 55.8508"
                stroke="#EDF8FB"
                strokeWidth="0.909147"
                strokeLinecap="round"
              />
            </g>
            <g filter="url(#filter1_d_11709_22187)">
              <path
                d="M168.362 79.6839L161.817 76.4153"
                stroke="#EDF8FB"
                strokeWidth="0.909147"
                strokeLinecap="round"
              />
            </g>
            <g filter="url(#filter2_d_11709_22187)">
              <path
                d="M168.362 76.7952L161.817 73.5266"
                stroke="#EDF8FB"
                strokeWidth="0.909147"
                strokeLinecap="round"
              />
            </g>
            <g filter="url(#filter3_d_11709_22187)">
              <path
                d="M168.362 73.8399L161.817 70.5713"
                stroke="#EDF8FB"
                strokeWidth="0.909147"
                strokeLinecap="round"
              />
            </g>
            <path
              d="M-15.0077 167.95L7.6482 162.393L35.0061 187.186L11.0679 205.994H-17.9999L-15.0077 167.95Z"
              fill="#337A85"
            />
            <path
              d="M138.466 134.119C138.928 129.806 132.048 127.252 128.261 126.995L121.137 126.995L91.0464 144.546C90.7255 147.499 91.0849 153.558 95.09 154.174C99.095 154.79 100.096 145.702 100.096 141.08L107.066 137.313C111.559 137.763 122.009 138.548 124.795 138.548C128.309 138.548 137.889 139.511 138.466 134.119Z"
              fill="#337A85"
            />
            <path
              d="M102.523 151.285C107.483 151.161 108.413 139.969 108.258 134.389L98.1828 138.109C97.5628 142.553 97.5628 151.409 102.523 151.285Z"
              fill="#57B7C6"
            />
            <rect width="38.8109" height="25.2243" transform="matrix(-1 0 0 1 109.307 107.547)" fill="#337A85" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129.793 125.107C133.28 125.859 138.04 126.885 140.199 122.566C143.016 116.932 131.098 105.324 108.043 101.578C84.9881 97.8315 70.3033 105.86 65.7063 108.878C62.3617 111.074 53.4512 121.583 44.9282 131.635C37.7223 140.134 30.7934 148.306 27.7392 150.851C21.2415 156.266 17.6034 157.171 11.3433 158.728C8.71024 159.383 5.61327 160.154 1.64453 161.424C-11.7598 165.713 26.4873 193.773 33.815 190.735C41.1427 187.696 60.2902 179.159 69.2026 173.219C75.9288 168.737 80.5647 162.437 84.6829 156.841C88.1554 152.122 91.2598 147.903 94.939 145.696C97.1977 144.341 99.8483 142.925 102.556 141.479C109.489 137.776 116.796 133.874 118.852 130.275L127.491 124.684C128.14 124.751 128.928 124.921 129.793 125.107ZM91.9004 125.489C87.0749 127.466 79.8936 129.789 73.4918 129.253C78.3173 127.466 88.1472 121.568 89.3983 119.245C86.0025 118.172 82.428 111.381 82.6067 108.878C82.6223 108.91 82.6406 108.947 82.6616 108.989C83.3596 110.397 86.9962 117.732 92.3303 118.63C95.2297 119.118 98.4903 118.12 100.342 117.554C100.452 117.52 100.558 117.488 100.658 117.457L102.482 119.245L91.9004 125.489Z"
              fill="#57B7C6"
            />
            <path
              d="M114.24 93.7236C113.989 93.2888 113.433 93.1398 112.998 93.3909L93.0661 104.899C92.6313 105.15 92.4823 105.706 92.7333 106.141L92.9016 106.432C93.0282 106.651 93.2406 106.808 93.4875 106.864L95.0706 107.224C95.3175 107.28 95.5298 107.436 95.6564 107.656L134.613 175.131C137.552 180.221 144.061 181.966 149.152 179.027C154.242 176.088 155.987 169.578 153.048 164.488L114.091 97.0127C113.964 96.7934 113.935 96.5313 114.01 96.2894L114.489 94.7385C114.564 94.4966 114.535 94.2344 114.408 94.0151L114.24 93.7236Z"
              fill="#FDFFFF"
            />
            <path
              d="M124.038 135.397C117.606 137.328 115.551 136.795 115.551 136.795L136.919 173.805C139.123 177.623 144.006 178.932 147.825 176.727C151.643 174.522 152.952 169.64 150.747 165.821L133.512 135.969C133.512 135.969 130.47 133.466 124.038 135.397Z"
              fill="#D3594E"
              stroke="#D3594E"
              strokeWidth="2.28044"
            />
            <path
              opacity="0.7"
              d="M112.632 102.485C112.22 101.772 111.309 101.528 110.596 101.939C109.883 102.351 109.639 103.262 110.05 103.975L146.03 166.293C146.441 167.006 147.353 167.25 148.066 166.838C148.778 166.427 149.023 165.515 148.611 164.802L112.632 102.485Z"
              fill={theme.colors.grayPorcelain}
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <path
                d="M96.3706 106.748L103.205 102.743L100.12 107.096L136.708 171.022C139.199 175.374 143.881 178.004 148.894 177.867C144.182 180.588 138.156 178.977 135.431 174.267L96.3706 106.748Z"
                fill="#D9D9D9"
              />
            </g>
            <path
              d="M129.175 114.257C124.399 107.985 109.08 115.54 102.017 120.101L94.6057 124.681L97.8099 144.771C98.582 144.118 110.407 136.944 114.788 134.831C126.977 128.954 135.144 122.097 129.175 114.257Z"
              fill="#337A85"
            />
            <path
              d="M127.124 114.941C122.348 108.668 107.029 116.223 99.9661 120.785L92.5547 125.365L97.1094 144.459C97.8816 143.806 106.448 139.344 111.354 136.591C125.738 128.516 133.093 122.781 127.124 114.941Z"
              fill="#57B7C6"
            />
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse cx="224.569" cy="233.289" rx="124.569" ry="3.13561" fill="#D9D9D9" />
          </g>
          <rect x="328.207" y="126.456" width="14.5463" height="107.279" rx="3.63659" fill="#85CDDD" />
          <path
            d="M197.29 127.365H219.11V211.006C219.11 217.032 214.225 221.916 208.2 221.916C202.175 221.916 197.29 217.032 197.29 211.006V127.365Z"
            fill="#F1F5F6"
          />
          <path
            d="M200.016 157.703H216.384V211.01C216.384 215.53 212.72 219.194 208.2 219.194C203.68 219.194 200.016 215.53 200.016 211.01V157.703Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.81829"
          />
          <path
            d="M231.838 127.365H253.657V211.006C253.657 217.032 248.773 221.916 242.747 221.916C236.722 221.916 231.838 217.032 231.838 211.006V127.365Z"
            fill="#FDFFFF"
          />
          <path
            d="M266.385 127.365H288.205V211.006C288.205 217.032 283.32 221.916 277.295 221.916C271.27 221.916 266.385 217.032 266.385 211.006V127.365Z"
            fill="#FDFFFF"
          />
          <path
            d="M269.111 152.1H285.479V211.011C285.479 215.53 281.814 219.194 277.295 219.194C272.775 219.194 269.111 215.53 269.111 211.011V152.1Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.81829"
          />
          <path
            d="M234.563 152.1H250.931V211.011C250.931 215.53 247.267 219.194 242.747 219.194C238.227 219.194 234.563 215.53 234.563 211.011V152.1Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.81829"
          />
          <path
            opacity="0.7"
            d="M270.484 135.25C270.484 134.407 271.168 133.723 272.012 133.723C272.856 133.723 273.54 134.407 273.54 135.25L273.54 209.012C273.54 209.856 272.856 210.54 272.012 210.54C271.168 210.54 270.484 209.856 270.484 209.012V135.25Z"
            fill={theme.colors.grayPorcelain}
          />
          <path
            opacity="0.7"
            d="M200.925 135.25C200.925 134.407 201.609 133.723 202.453 133.723C203.297 133.723 203.981 134.407 203.981 135.25L203.981 209.012C203.981 209.856 203.297 210.54 202.453 210.54C201.609 210.54 200.925 209.856 200.925 209.012V135.25Z"
            fill={theme.colors.grayPorcelain}
          />
          <path
            opacity="0.7"
            d="M235.705 135.25C235.705 134.407 236.389 133.723 237.233 133.723C238.077 133.723 238.761 134.407 238.761 135.25L238.761 209.012C238.761 209.856 238.077 210.54 237.233 210.54C236.389 210.54 235.705 209.856 235.705 209.012V135.25Z"
            fill={theme.colors.grayPorcelain}
          />
          <rect x="182.744" y="141.002" width="152.737" height="7.27317" rx="3.63659" fill="#85CDDD" />
          <path
            d="M193.653 114.637C193.653 112.628 195.281 111 197.29 111H219.109C221.118 111 222.746 112.628 222.746 114.637V125.546C222.746 127.555 221.118 129.183 219.109 129.183H197.29C195.281 129.183 193.653 127.555 193.653 125.546V114.637Z"
            fill="#68809B"
          />
          <path
            d="M195.539 114.91C195.539 113.522 196.664 112.397 198.052 112.397C199.44 112.397 200.565 113.522 200.565 114.91V125.273C200.565 126.66 199.44 127.785 198.052 127.785C196.664 127.785 195.539 126.66 195.539 125.273V114.91Z"
            fill="#98AABF"
          />
          <path
            d="M228.201 114.637C228.201 112.628 229.829 111 231.837 111H253.657C255.665 111 257.293 112.628 257.293 114.637V125.546C257.293 127.555 255.665 129.183 253.657 129.183H231.837C229.829 129.183 228.201 127.555 228.201 125.546V114.637Z"
            fill="#68809B"
          />
          <path
            d="M262.749 114.637C262.749 112.628 264.377 111 266.385 111H288.205C290.213 111 291.841 112.628 291.841 114.637V125.546C291.841 127.555 290.213 129.183 288.205 129.183H266.385C264.377 129.183 262.749 127.555 262.749 125.546V114.637Z"
            fill="#68809B"
          />
          <path d="M185.42 141.002H189.981L192.014 148.275H185.42V141.002Z" fill="#74BED5" />
          <rect x="335.48" y="133.321" width="4.56138" height="98.1879" rx="2.28069" fill="#74BED5" />
          <path
            d="M230.019 114.91C230.019 113.522 231.144 112.397 232.532 112.397C233.92 112.397 235.045 113.522 235.045 114.91V125.273C235.045 126.66 233.92 127.785 232.532 127.785C231.144 127.785 230.019 126.66 230.019 125.273V114.91Z"
            fill="#98AABF"
          />
          <path
            d="M264.499 114.91C264.499 113.522 265.624 112.397 267.012 112.397C268.4 112.397 269.525 113.522 269.525 114.91V125.273C269.525 126.66 268.4 127.785 267.012 127.785C265.624 127.785 264.499 126.66 264.499 125.273V114.91Z"
            fill="#98AABF"
          />
          <rect x="171.834" y="126.456" width="14.5463" height="107.279" rx="3.63659" fill="#85CDDD" />
          <rect x="179.107" y="133.321" width="4.56138" height="98.1879" rx="2.28069" fill="#74BED5" />
          <rect x="182.744" y="193.733" width="152.737" height="7.27317" rx="3.63659" fill="#85CDDD" />
          <path d="M185.42 193.733H189.981L192.014 201.006H185.42V193.733Z" fill="#74BED5" />
          <defs>
            <filter
              id="filter0_d_11709_22187"
              x="161.355"
              y="42.119"
              width="51.4244"
              height="45.7937"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.36372" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11709_22187" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11709_22187" result="shape" />
            </filter>
            <filter
              id="filter1_d_11709_22187"
              x="158.634"
              y="73.2333"
              width="12.9095"
              height="9.63286"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.36372" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11709_22187" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11709_22187" result="shape" />
            </filter>
            <filter
              id="filter2_d_11709_22187"
              x="158.635"
              y="70.3446"
              width="12.9095"
              height="9.63286"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.36372" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11709_22187" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11709_22187" result="shape" />
            </filter>
            <filter
              id="filter3_d_11709_22187"
              x="158.635"
              y="67.3893"
              width="12.9095"
              height="9.63286"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1.36372" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11709_22187" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11709_22187" result="shape" />
            </filter>
            <clipPath id="clip0_11709_22187">
              <rect width="318" height="253" rx="8" fill={theme.colors.grayPorcelain} />
            </clipPath>
          </defs>
        </svg>
      </Display>
      <Display isNotebookDown>
        <svg width="100%" height="100%" viewBox="0 0 342 116" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11369_60260)">
            <rect width="342" height="101.691" rx="4.54485" fill="#CEE9EE" />
            <path
              d="M329.065 55.738C339.117 95.9464 329.503 93.0737 323.366 121.229L11.0938 116.562C11.0938 37.0693 37.066 25.8373 97.8133 32.8537C133.361 36.9595 168.63 25.1565 214.644 14.7873C257.401 5.15178 318.827 14.7873 329.065 55.738Z"
              fill="#BBDCE1"
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <path
                d="M238.372 49.7451C238.372 56.1202 209.358 61.2883 173.568 61.2883C137.778 61.2883 142.154 55.1541 142.154 48.779C142.154 42.4038 137.778 38.2019 173.568 38.2019C209.358 38.2019 238.372 43.37 238.372 49.7451Z"
                fill="#D9D9D9"
              />
            </g>
            <path
              d="M351.704 83.0302L330.453 77.8176L304.791 101.074L327.245 118.716H354.511L351.704 83.0302Z"
              fill="#337A85"
            />
            <path
              d="M207.744 51.2971C207.311 47.2513 213.765 44.8551 217.317 44.6143L224 44.6143L252.225 61.0776C252.526 63.8471 252.189 69.5305 248.432 70.1084C244.675 70.6864 243.736 62.1613 243.736 57.8266L237.198 54.293C232.983 54.7144 223.181 55.4513 220.568 55.4513C217.272 55.4513 208.286 56.3543 207.744 51.2971Z"
              fill="#337A85"
            />
            <path
              d="M241.46 67.3981C236.807 67.2817 235.934 56.7839 236.08 51.5496L245.531 55.0392C246.112 59.2073 246.112 67.5144 241.46 67.3981Z"
              fill="#57B7C6"
            />
            <rect x="235.096" y="26.3718" width="36.405" height="23.6606" fill="#337A85" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M215.88 42.8438C212.609 43.549 208.144 44.5117 206.119 40.4601C203.476 35.1753 214.656 24.2872 236.282 20.773C257.907 17.2588 271.682 24.7901 275.994 27.6209C279.131 29.6805 287.489 39.5381 295.484 48.9669C302.243 56.9387 308.743 64.6041 311.607 66.9915C317.702 72.0705 321.115 72.9195 326.987 74.3804C329.457 74.9948 332.362 75.7176 336.084 76.9088C348.658 80.9323 312.782 107.253 305.908 104.403C299.035 101.553 281.074 93.5447 272.714 87.9734C266.405 83.7688 262.057 77.8595 258.194 72.6101C254.936 68.1838 252.024 64.2267 248.573 62.156C246.455 60.8848 243.968 59.5569 241.429 58.2006C234.925 54.7273 228.072 51.0671 226.143 47.6913L218.039 42.4469C217.43 42.5096 216.692 42.6688 215.88 42.8438ZM251.423 43.2019C255.949 45.056 262.686 47.2354 268.691 46.7324C264.164 45.056 254.944 39.5237 253.77 37.3443C256.955 36.3384 260.308 29.9679 260.141 27.6208C260.126 27.6501 260.109 27.6847 260.089 27.7244C259.434 29.045 256.023 35.9256 251.02 36.7675C248.3 37.2251 245.242 36.2895 243.505 35.7583C243.401 35.7266 243.302 35.6963 243.208 35.6678L241.497 37.3443L251.423 43.2019Z"
              fill="#57B7C6"
            />
            <path
              d="M230.61 13.1587C230.767 12.887 231.114 12.7939 231.386 12.9508L250.575 24.0298C250.847 24.1867 250.94 24.5342 250.783 24.8059L250.421 25.4336C250.342 25.5706 250.209 25.6684 250.055 25.7035L248.327 26.0961C248.173 26.1312 248.041 26.229 247.961 26.3661L211.357 89.7661C208.601 94.541 202.495 96.1771 197.72 93.4203C192.945 90.6635 191.309 84.5578 194.066 79.7829L230.67 16.3828C230.749 16.2458 230.767 16.082 230.721 15.9308L230.197 14.2384C230.15 14.0873 230.168 13.9235 230.248 13.7864L230.61 13.1587Z"
              fill="#FDFFFF"
            />
            <path
              d="M221.277 52.4959C227.31 54.3072 229.238 53.8073 229.238 53.8073L209.195 88.5225C207.127 92.1044 202.547 93.3317 198.965 91.2636C195.383 89.1956 194.156 84.6155 196.224 81.0336L212.39 53.0323C212.39 53.0323 215.244 50.6846 221.277 52.4959Z"
              fill="#D3594E"
              stroke="#D3594E"
              strokeWidth="2.84053"
            />
            <path
              opacity="0.7"
              d="M231.976 21.6236C232.362 20.9549 233.217 20.7258 233.886 21.1119C234.554 21.4979 234.783 22.353 234.397 23.0217L200.648 81.4762C200.262 82.1449 199.407 82.374 198.739 81.988C198.07 81.6019 197.841 80.7469 198.227 80.0782L231.976 21.6236Z"
              fill={theme.colors.grayPorcelain}
            />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
              <path
                d="M247.229 25.6222L240.818 21.8662L243.712 25.9494L209.393 85.9121C207.056 89.9947 202.664 92.4616 197.961 92.3326L198.097 92.4111C202.442 94.9205 207.999 93.4347 210.511 89.0915L247.229 25.6222Z"
                fill="#D9D9D9"
              />
            </g>
            <path
              d="M216.46 32.6657C220.939 26.7822 235.309 33.8689 241.934 38.1477L248.886 42.4435L245.88 61.2884C245.156 60.6755 234.064 53.9459 229.955 51.9645C218.521 46.4518 210.861 40.02 216.46 32.6657Z"
              fill="#337A85"
            />
            <path
              d="M218.384 33.3068C222.863 27.4234 237.233 34.51 243.858 38.7888L250.81 43.0846L246.538 60.9953C245.813 60.3824 237.778 56.1975 233.176 53.6147C219.683 46.0409 212.785 40.6611 218.384 33.3068Z"
              fill="#57B7C6"
            />
            <path
              d="M135.323 55.1L149.189 60.5327L182.313 8.68904L172.251 7.16283C171.463 7.04324 170.657 7.10972 169.899 7.35697L140.884 16.8193C139.102 17.4005 137.781 18.9102 137.441 20.7537L132.16 49.4128C131.716 51.8215 133.042 54.2066 135.323 55.1Z"
              fill="#A5CCD2"
            />
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
            <ellipse cx="120.584" cy="107.94" rx="85.943" ry="1.89447" fill="#D9D9D9" />
          </g>
          <rect x="131.8" y="41.4717" width="9.0897" height="67.0365" rx="2.27243" fill="#85CDDD" />
          <path
            d="M49.9932 42.04H63.6277V94.3058C63.6277 98.0709 60.5755 101.123 56.8104 101.123C53.0454 101.123 49.9932 98.0709 49.9932 94.3058V42.04Z"
            fill="#F1F5F6"
          />
          <path
            d="M51.6962 60.998H61.9241V94.3085C61.9241 97.1329 59.6345 99.4225 56.8101 99.4225C53.9858 99.4225 51.6962 97.1329 51.6962 94.3085V60.998Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.13621"
          />
          <path
            d="M71.5807 42.0398H85.2152V94.3056C85.2152 98.0706 82.163 101.123 78.398 101.123C74.6329 101.123 71.5807 98.0707 71.5807 94.3056V42.0398Z"
            fill="#FDFFFF"
          />
          <path
            d="M93.1693 42.04H106.804V94.3058C106.804 98.0709 103.752 101.123 99.9866 101.123C96.2215 101.123 93.1693 98.0709 93.1693 94.3058V42.04Z"
            fill="#FDFFFF"
          />
          <path
            d="M94.8724 57.4968H105.1V94.3087C105.1 97.1331 102.811 99.4227 99.9864 99.4227C97.162 99.4227 94.8724 97.1331 94.8724 94.3087V57.4968Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.13621"
          />
          <path
            d="M73.2843 57.4966H83.5123V94.3085C83.5123 97.1328 81.2227 99.4224 78.3983 99.4224C75.5739 99.4224 73.2843 97.1328 73.2843 94.3085V57.4966Z"
            fill="#D3594E"
            stroke="#D3594E"
            strokeWidth="1.13621"
          />
          <path
            opacity="0.7"
            d="M95.7305 46.9674C95.7305 46.4401 96.1579 46.0127 96.6852 46.0127C97.2124 46.0127 97.6399 46.4401 97.6399 46.9674L97.6398 93.0595C97.6398 93.5867 97.2124 94.0141 96.6852 94.0141C96.1579 94.0141 95.7305 93.5867 95.7305 93.0595V46.9674Z"
            fill={theme.colors.grayPorcelain}
          />
          <path
            opacity="0.7"
            d="M52.2643 46.9674C52.2643 46.4401 52.6917 46.0127 53.219 46.0127C53.7463 46.0127 54.1737 46.4401 54.1737 46.9674L54.1737 93.0595C54.1737 93.5867 53.7462 94.0141 53.219 94.0141C52.6917 94.0141 52.2643 93.5867 52.2643 93.0595V46.9674Z"
            fill={theme.colors.grayPorcelain}
          />
          <path
            opacity="0.7"
            d="M73.9978 46.9676C73.9978 46.4404 74.4252 46.0129 74.9525 46.0129C75.4798 46.0129 75.9072 46.4404 75.9072 46.9676L75.9072 93.0597C75.9072 93.587 75.4798 94.0144 74.9525 94.0144C74.4252 94.0144 73.9978 93.587 73.9978 93.0597V46.9676Z"
            fill={theme.colors.grayPorcelain}
          />
          <rect x="40.9033" y="50.5615" width="95.4419" height="4.54485" rx="2.27243" fill="#85CDDD" />
          <path
            d="M47.7205 34.0864C47.7205 32.8314 48.7379 31.814 49.9929 31.814H63.6274C64.8825 31.814 65.8999 32.8314 65.8999 34.0864V40.9037C65.8999 42.1587 64.8825 43.1761 63.6274 43.1761H49.9929C48.7379 43.1761 47.7205 42.1587 47.7205 40.9037V34.0864Z"
            fill="#68809B"
          />
          <path
            d="M48.8989 34.2574C48.8989 33.3902 49.6019 32.6873 50.4691 32.6873C51.3363 32.6873 52.0393 33.3902 52.0393 34.2574V40.7329C52.0393 41.6001 51.3363 42.303 50.4691 42.303C49.6019 42.303 48.8989 41.6001 48.8989 40.7329V34.2574Z"
            fill="#98AABF"
          />
          <path
            d="M69.3086 34.0866C69.3086 32.8316 70.326 31.8142 71.581 31.8142H85.2156C86.4706 31.8142 87.488 32.8316 87.488 34.0866V40.9039C87.488 42.1589 86.4706 43.1763 85.2156 43.1763H71.581C70.326 43.1763 69.3086 42.1589 69.3086 40.9039V34.0866Z"
            fill="#68809B"
          />
          <path
            d="M90.8967 34.0864C90.8967 32.8314 91.9141 31.814 93.1692 31.814H106.804C108.059 31.814 109.076 32.8314 109.076 34.0864V40.9037C109.076 42.1587 108.059 43.1761 106.804 43.1761H93.1692C91.9141 43.1761 90.8967 42.1587 90.8967 40.9037V34.0864Z"
            fill="#68809B"
          />
          <path d="M42.5752 50.5615H45.4255L46.6961 55.1064H42.5752V50.5615Z" fill="#74BED5" />
          <rect x="136.345" y="45.762" width="2.85031" height="61.3555" rx="1.42515" fill="#74BED5" />
          <path
            d="M70.4448 34.2577C70.4448 33.3905 71.1478 32.6875 72.015 32.6875C72.8822 32.6875 73.5852 33.3905 73.5852 34.2577V40.7331C73.5852 41.6003 72.8822 42.3033 72.015 42.3033C71.1478 42.3033 70.4448 41.6003 70.4448 40.7331V34.2577Z"
            fill="#98AABF"
          />
          <path
            d="M91.9904 34.2574C91.9904 33.3902 92.6933 32.6873 93.5605 32.6873C94.4277 32.6873 95.1307 33.3902 95.1307 34.2574V40.7329C95.1307 41.6001 94.4277 42.303 93.5605 42.303C92.6933 42.303 91.9904 41.6001 91.9904 40.7329V34.2574Z"
            fill="#98AABF"
          />
          <rect x="34.0862" y="41.4722" width="9.0897" height="67.0365" rx="2.27243" fill="#85CDDD" />
          <rect x="38.6311" y="45.762" width="2.85031" height="61.3555" rx="1.42515" fill="#74BED5" />
          <rect x="40.9033" y="83.5115" width="95.4419" height="4.54485" rx="2.27243" fill="#85CDDD" />
          <path d="M42.5752 83.5115H45.4255L46.6961 88.0563H42.5752V83.5115Z" fill="#74BED5" />
          <path
            d="M192.254 39.026L185.838 12.087C185.213 9.46572 182.459 7.9602 179.915 8.85036L151.002 18.9702C149.26 19.5797 148.06 21.1815 147.964 23.0241L146.249 56.0389C146.074 59.4137 149.512 61.7926 152.609 60.439L189.654 44.2434C191.669 43.3625 192.764 41.1653 192.254 39.026Z"
            fill="#8CB9C0"
          />
          <path
            d="M185.658 41.152L182.056 13.8151C181.962 13.1005 181.234 12.653 180.554 12.8912L152.987 22.5397C152.504 22.7086 152.194 23.179 152.229 23.6891L154.272 53.7967C154.325 54.5769 155.132 55.0717 155.851 54.7649L184.978 42.3456C185.447 42.1456 185.725 41.6576 185.658 41.152Z"
            fill="#708B8F"
          />
          <path
            d="M152.229 23.3986L154.252 53.4297C154.277 53.7973 154.637 54.0428 154.985 53.9235C156.685 53.3417 161.579 51.5891 169.973 47.9859C177.348 44.8202 181.504 42.7639 183.364 41.7976C184.109 41.4105 184.547 40.6315 184.506 39.7926C184.394 37.4913 184.093 32.5133 183.389 26.7356C182.596 20.2291 181.32 15.113 180.878 13.4536C180.795 13.1407 180.465 12.9686 180.158 13.0723L152.999 22.2458C152.51 22.4108 152.194 22.8841 152.229 23.3986Z"
            fill="#5F6262"
          />
          <g filter="url(#filter0_d_11369_60260)">
            <path
              d="M156.361 38.1581L161.309 36.3698C161.395 36.3387 161.461 36.2683 161.486 36.1805L162.356 33.1259C162.432 32.8616 162.801 32.8476 162.896 33.1054L166.797 43.6805C166.905 43.9739 167.339 43.9026 167.347 43.59L167.877 24.1899C167.886 23.8724 168.33 23.8063 168.431 24.1076L172.535 36.3751C172.633 36.6681 173.062 36.6161 173.087 36.3082L173.638 29.5874C173.66 29.3205 174.005 29.2294 174.156 29.4507L175.168 30.9366C175.246 31.0511 175.395 31.0926 175.521 31.035L179.661 29.1394"
              stroke="#EDF8FB"
              strokeWidth="0.568106"
              strokeLinecap="round"
            />
          </g>
          <g filter="url(#filter1_d_11369_60260)">
            <path d="M177.684 41.4636L181.068 39.7734" stroke="#EDF8FB" strokeWidth="0.568106" strokeLinecap="round" />
          </g>
          <g filter="url(#filter2_d_11369_60260)">
            <path d="M177.684 39.9698L181.068 38.2795" stroke="#EDF8FB" strokeWidth="0.568106" strokeLinecap="round" />
          </g>
          <g filter="url(#filter3_d_11369_60260)">
            <path d="M177.684 38.4417L181.068 36.7515" stroke="#EDF8FB" strokeWidth="0.568106" strokeLinecap="round" />
          </g>
          <defs>
            <filter
              id="filter0_d_11369_60260"
              x="154.372"
              y="21.9248"
              width="27.2777"
              height="23.9304"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.852159" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11369_60260" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11369_60260" result="shape" />
            </filter>
            <filter
              id="filter1_d_11369_60260"
              x="175.695"
              y="37.7849"
              width="7.3614"
              height="5.66718"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.852159" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11369_60260" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11369_60260" result="shape" />
            </filter>
            <filter
              id="filter2_d_11369_60260"
              x="175.695"
              y="36.291"
              width="7.3614"
              height="5.66718"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.852159" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11369_60260" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11369_60260" result="shape" />
            </filter>
            <filter
              id="filter3_d_11369_60260"
              x="175.695"
              y="34.763"
              width="7.3614"
              height="5.66718"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="0.852159" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.972549 0 0 0 0 0.984314 0 0 0 0.8 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11369_60260" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11369_60260" result="shape" />
            </filter>
            <clipPath id="clip0_11369_60260">
              <rect width="342" height="101.691" rx="4.54485" fill={theme.colors.grayPorcelain} />
            </clipPath>
          </defs>
        </svg>
      </Display>
    </>
  )
}
