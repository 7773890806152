import { searchQueryParamName } from '../../../common/constants/queryParams'
import {
  makeRouteFromDynamicSegmentSignature,
  shopLabsPackageDynamicSignature,
  shopLabTestsRoute,
  shopMedicationsRoute,
  shopSupplementsRoute,
} from '../../../common/constants/routes'
import { enum_stripe_product_type_enum } from '../../../generated/graphql'

type Input = { slug: string; product_type: enum_stripe_product_type_enum }

export const getProductShopLink = ({ slug, product_type }: Input) =>
  product_type === enum_stripe_product_type_enum.PACKAGE
    ? makeRouteFromDynamicSegmentSignature(shopLabsPackageDynamicSignature, slug)
    : product_type === enum_stripe_product_type_enum.LAB_TEST ||
        product_type === enum_stripe_product_type_enum.LAB_PANEL
      ? `${shopLabTestsRoute}?${searchQueryParamName}=${slug}`
      : product_type === enum_stripe_product_type_enum.PRESCRIPTION
        ? `${shopMedicationsRoute}/${slug}`
        : product_type == enum_stripe_product_type_enum.SUPPLEMENT
          ? `${shopSupplementsRoute}/${slug}`
          : null
