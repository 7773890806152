import * as React from 'react'
import { shopLabsPackagesRoute, shopMedicationsRoute, shopSupplementsRoute } from '../../../common/constants/routes'
import { DisableableLink, NextLink } from '../../../common/functionality/NextLink'
import { StandardPage } from '../../../common/page-components/StandardPage'
import { notebookUpMq, tabletDownMq } from '../../../styles/mediaQueries'
import { theme } from '../../../styles/theme'
import styled from '@emotion/styled'
import { SubmitButton } from '../../../common/form-components/SubmitButton'
import { gql } from '@apollo/client'
import { Display } from '../../../common/style/Display'
import { useShopPageFeaturedProductsQuery, useUserSexQuery } from '../../../generated/graphql'
import { LEGACY_NextImage } from '../../../common/functionality/LEGACY_NextImage'
import { useProductDefaultImages } from '../hooks/useProductDefaultImages'
import { ellipsisLineClampCss } from '../../../common/style/ellipsisCss'
import { useDevice } from '../../../hooks/useDevice'
import { ShopLabsHero } from '../assets/ShopLabsHero'
import { ShopSupplementsHero } from '../assets/ShopSupplementsHero'
import { ShopMedicationsHero } from '../assets/ShopMedicationsHero'
import { HorizontalCarousel } from '../organisms/HorizontalCarousel'
import { typedFalsyFilter } from '../../../typescript/guards/typedFalsyFilter'
import { css } from '@emotion/react'
import { LazyMarkdownRenderer } from '../../../common/components/MarkdownRenderer/LazyMarkdownRenderer'
import { ShopHeroBanner, getShopPageHeroImage } from '../atoms/ShopHeroBanner'
import { getProductShopLink } from '../utils/getProductShopLink'
import { useFeatureFlag } from '../../auth/hooks/useFeatureFlag'
import { featureFlags } from '../../auth/FeatureFlagProvider'
import { HtmlHead } from '../../../common/page-components/HtmlHead'
import { useAccount } from '../../auth/hooks/useAccount'
import { isPackageForOppositeSex } from '../features/labs/utils/isPackageForOppositeSex'
import { getPrices } from '../features/labs/hooks/useLabProducts'

// const Subheading = styled.div`
//   color: ${theme.colors.lightCloud};
//   font-size: 20px;
//   font-weight: 500;
// `
const Section = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: ${theme.colors.grayPorcelain};
  padding: 32px;

  :not(:last-of-type) {
    margin-bottom: 16px;
    ${tabletDownMq} {
      margin-bottom: 8px;
    }
  }

  ${tabletDownMq} {
    padding: 16px;
  }
`

const RowWrapper = styled.div`
  margin-bottom: 16px;
`

const SectionHeader = styled.div`
  color: ${theme.colors.grayMidnight};
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  display: flex;
  align-items: center;
`
const SectionSubtitle = styled.div`
  color: ${theme.colors.grayCoolGray};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08px;
`
const SectionDescription = styled.div`
  color: ${theme.colors.grayWarmGray};
  font-size: 15px;
  font-weight: 600;
`

const CtaRow = styled.div`
  padding: 8px 0;
`

const Cta = styled(SubmitButton)`
  padding: 0 20px;
  width: fit-content;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  ${theme.colors.grayLightSilver};
  margin: 24px 0;
`

const ProductContainer = styled.div`
  display: flex;
  width: 333px;
  margin: 8px 0;
  margin-right: 32px;
  max-height: 105px;
  overflow: hidden;
`
const ImageContainer = styled.div`
  border-radius: 8px;
  background: ${theme.colors.grayLightLightGray};
  margin-right: 16px;
  min-width: 158.5px;
  height: 105px;
  position: relative;
`
const ProductImage = styled(LEGACY_NextImage)`
  mix-blend-mode: multiply;
  min-width: 158.5px;
  height: 105px;
`

const Name = styled.div`
  color: ${theme.colors.grayMidnight};
  font-size: 22px;
  font-weight: 700;
`
const Summary = styled.div`
  margin-top: 8px;
  color: ${theme.colors.grayCoolGray};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.07px;

  ${ellipsisLineClampCss(3)}
`

export const ComingSoonIndicator = styled.div`
  border-radius: 20px;
  border: 1px solid ${theme.colors.aegean};
  display: flex;
  height: 28px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: ${theme.colors.aegean};
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
`

const ComingSoonMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(249, 249, 251, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const ComingSoonProductImageOverlay = ({ text }: { text?: string }) => (
  <ComingSoonMask>
    <ComingSoonIndicator>{text ?? 'Coming Soon'}</ComingSoonIndicator>
  </ComingSoonMask>
)

const ProductRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

gql`
  fragment ShopPageStripeProductFields on cms_StripeProductEntity_content {
    attributes {
      product {
        ...StripeProductPriceFields
        id
        product_type
        name
      }
      slug
      summary
      coming_soon
      out_of_stock
      images(pagination: { limit: 1 }) {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
    }
  }

  fragment Section on cms_ComponentShopSectionShopSection_content {
    id
    Title
    Subtitle
    Description
    CTA_Text
    stripe_products {
      data {
        ...ShopPageStripeProductFields
      }
    }
  }

  query ShopPageFeaturedProducts @cached(ttl: 300) {
    cms {
      shopPageFeatured {
        data {
          attributes {
            lab_tests {
              ...Section
            }
            supplements {
              ...Section
            }
            # medications {
            #   ...Section
            # }
          }
        }
      }
    }
  }
`
enum contentIds {
  labTests = 'labTests',
  supplements = 'supplements',
  'medications' = 'medications',
}

const contentdict = {
  [contentIds.labTests]: {
    Hero: ShopLabsHero,
    route: shopLabsPackagesRoute,
  },
  [contentIds.supplements]: {
    Hero: ShopSupplementsHero,
    route: shopSupplementsRoute,
  },
  [contentIds.medications]: {
    Hero: ShopMedicationsHero,
    route: shopMedicationsRoute,
  },
}

export const useShopPageSections = () => {
  const { data, loading } = useShopPageFeaturedProductsQuery()

  const { isAuthenticated } = useAccount()
  const userSex = useUserSexQuery({ skip: !isAuthenticated }).data?.me_v2?.sex

  const defaultImages = useProductDefaultImages().productDefaultImages

  const isOverrideComingSoonEnabled = useFeatureFlag(featureFlags.overrideComingSoon).isEnabled

  const sections = React.useMemo(() => {
    const { lab_tests, supplements } = data?.cms?.shopPageFeatured?.data?.attributes ?? {}

    return [lab_tests, supplements]
      .map((group, i) => {
        const item = group?.[0]
        if (!item) return null
        const contentId =
          i === 0 ? contentIds.labTests : i === 1 ? contentIds.supplements : i == 2 ? contentIds.medications : null
        if (!contentId) return null

        const products = (item?.stripe_products?.data ?? [])
          .map((x) => {
            const base = x.attributes
            const image = base?.images?.data?.[0]?.attributes

            const slug = base?.slug
            const product_type = base?.product?.product_type ?? null
            const name = base?.product?.name
            const link = slug && product_type ? getProductShopLink({ slug, product_type }) : null

            if (
              !base?.product ||
              !slug ||
              !product_type ||
              !name ||
              !link ||
              isPackageForOppositeSex(name, userSex) ||
              base.out_of_stock
            ) {
              return null
            }

            return {
              slug,
              link,
              name,
              prices: getPrices(base.product),
              id: base.product?.id,
              product_type,
              summary: base.summary ?? '',
              image: image ?? { url: defaultImages[product_type], alternativeText: name },
              comingSoon: !!base.coming_soon && !isOverrideComingSoonEnabled,
            }
          })
          .filter(typedFalsyFilter)
        return {
          contentId,
          comingSoon: !!products.length && products.every((x) => x.comingSoon),
          title: item.Title,
          subtitle: item.Subtitle,
          description: item.Description,
          ctaText: item.CTA_Text,
          id: item.id,
          products,
        }
      })
      .filter(typedFalsyFilter)
  }, [data, defaultImages, isOverrideComingSoonEnabled, userSex])
  return { sections, loading }
}

const ResponsiveSplitSectionLink = styled(NextLink)`
  ${notebookUpMq} {
    display: flex;
  }
`

const HeroWrapper = styled.div`
  ${notebookUpMq} {
    margin-right: 16px;
  }
`

const SectionTextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export function ShopPage() {
  const { isTabletDown } = useDevice()

  const { sections } = useShopPageSections()
  return (
    <>
      <StandardPage
        styles={{
          pageContentContainer: {
            background: theme.colors.grayLightLightGray,
            maxWidth: theme.spacing.standardPageMaxContentWidth,
          },
          headerRightContent: css`
            ${tabletDownMq} {
              margin-bottom: 12px !important;
            }
          `,
        }}
        htmlHeadMetaData={
          <HtmlHead
            title={'Shop Supplements, Medications, and Lab Tests'}
            description="Your one stop shop for health optimization: lab tests, supplements, and prescription medication."
            image={getShopPageHeroImage('mobile')}
          />
        }
      >
        <ShopHeroBanner title={'Shop'} />
        {sections.map((s) => {
          const content = s?.contentId ? contentdict[s.contentId] : null
          if (!content) return null
          const { Hero } = content
          return (
            <Section key={s.contentId}>
              <ResponsiveSplitSectionLink href={content.route}>
                <HeroWrapper>
                  <Hero />
                </HeroWrapper>
                <SectionTextContentContainer>
                  <RowWrapper>
                    <SectionHeader>
                      {s.title}
                      {s.comingSoon && <ComingSoonIndicator style={{ marginLeft: 8 }}>coming soon</ComingSoonIndicator>}
                    </SectionHeader>
                  </RowWrapper>
                  {!!s.subtitle && (
                    <RowWrapper>
                      <SectionSubtitle>{s.subtitle}</SectionSubtitle>
                    </RowWrapper>
                  )}
                  {!!s.description && (
                    <RowWrapper>
                      <SectionDescription>
                        <LazyMarkdownRenderer markdown={s.description} />
                      </SectionDescription>
                    </RowWrapper>
                  )}
                  <CtaRow>
                    <Cta withComponent={'div'}>{s.ctaText}</Cta>
                  </CtaRow>
                </SectionTextContentContainer>
              </ResponsiveSplitSectionLink>
              <Display isTabletUp>
                <Divider />
                <HorizontalCarousel
                  hasArrows
                  overflowDistance={isTabletDown ? 16 : 32}
                  sidePadding={isTabletDown ? 16 : 32}
                  key={JSON.stringify(s.products)}
                >
                  {s.products.map((x) => {
                    const comingSoonProps = { disabled: true }
                    const regularProps = { href: x.link }

                    const props = x.comingSoon ? comingSoonProps : regularProps
                    return (
                      // @ts-ignore check note in DisableableLink
                      <DisableableLink key={x.slug} {...props}>
                        <ProductContainer>
                          <ImageContainer>
                            {x.comingSoon && <ComingSoonProductImageOverlay />}
                            <ProductImage
                              objectFit="contain"
                              layout="fill"
                              src={x.image?.url ?? ''}
                              alt={x.image?.alternativeText}
                            />
                          </ImageContainer>
                          <ProductRightSide>
                            <Name>{x.name}</Name>
                            <Summary>{x.summary}</Summary>
                          </ProductRightSide>
                        </ProductContainer>
                      </DisableableLink>
                    )
                  })}
                </HorizontalCarousel>
              </Display>
            </Section>
          )
        })}
      </StandardPage>
    </>
  )
}
